// Add any new approval types to enum below which is used internally

export let ApprovalTypes;
(function (ApprovalTypes) {
  ApprovalTypes["MARKETING_EMAIL"] = "MARKETING_EMAIL";
  ApprovalTypes["LANDING_PAGE"] = "LANDING_PAGE";
  ApprovalTypes["SITE_PAGE"] = "SITE_PAGE";
  ApprovalTypes["BLOG_POST"] = "BLOG_POST";
  ApprovalTypes["DEAL"] = "DEAL";
  ApprovalTypes["KNOWLEDGE_ARTICLE"] = "KNOWLEDGE_ARTICLE";
  ApprovalTypes["QUOTE"] = "QUOTE";
})(ApprovalTypes || (ApprovalTypes = {}));