const isOneLevelArrayEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }
  return array1.every((value, index) => value === array2[index]);
};
export const isObjectEqual = (object1, object2) => {
  const keys = Object.keys(object1);
  return keys.every(key => {
    const value1 = object1[key];
    const value2 = object2[key];
    if (Array.isArray(value1)) {
      return Array.isArray(value2) && isOneLevelArrayEqual(value1, value2);
    }
    return value1 === value2;
  });
};
export const filterDuplicateObjectsFromArray = objects => {
  const unDuplicatedObjects = [];
  objects.forEach(obj => {
    const metricInUnduplicatedMetrics = unDuplicatedObjects.find(unDuplicatedObject => isObjectEqual(obj, unDuplicatedObject));
    if (!metricInUnduplicatedMetrics) {
      unDuplicatedObjects.push(obj);
    }
  });
  return unDuplicatedObjects;
};
export const isObject = value => {
  return typeof value === 'object' && value !== null;
};
export const isEmptyObject = value => {
  return isObject(value) && !Object.keys(value).length && !Array.isArray(value);
};