import http from 'hub-http/clients/apiClient';
import { createRpcClientV2 } from 'rpc-client-utils';
import { checkout as createCheckoutUrl } from 'self-service-api/core/utilities/links';
import { createPurchaseOrderRpc } from '../../../__generated__/chirp/com/hubspot/monetization/rpc/PurchaseChirpService';
import logPurchaseOrderError from '../../_core/common/reliability/logCheckoutError';
import { getChirpError, getChirpInternalError } from '../../_core/utils/errors';
import { incrementPurchaseOrderGenerationFailure, incrementPurchaseOrderGenerationSuccess } from '../../_core/utils/metricUtils';
import { trackPurchaseOrderGenerated, trackPurchaseOrderGenerationFailed } from './buy-now-trackers';
const rpcClient = createRpcClientV2({
  hubHttpClient: http
});
export const generateCheckoutUrl = ({
  buyNowUsageTrackingProperties,
  currencyCode,
  requestedProducts,
  terms = {
    commitmentTerm: 'ANNUAL',
    paymentTerm: 'ANNUAL'
  }
}) => {
  const request = {
    commitmentTerm: terms === null || terms === void 0 ? void 0 : terms.commitmentTerm,
    currencyCode,
    paymentTerm: terms === null || terms === void 0 ? void 0 : terms.paymentTerm,
    products: requestedProducts.map(({
      productApiName,
      quantity
    }) => ({
      productApiName,
      quantity: quantity || 1
    }))
  };
  return rpcClient.call(createPurchaseOrderRpc, {
    request
  }).then(purchaseOrderResponse => {
    let upgradeSource;
    if (buyNowUsageTrackingProperties) {
      trackPurchaseOrderGenerated(buyNowUsageTrackingProperties);
      upgradeSource = buyNowUsageTrackingProperties.upgradeSource;
    }
    incrementPurchaseOrderGenerationSuccess({
      type: 'buy-now'
    });
    return createCheckoutUrl(purchaseOrderResponse.purchaseOrderId.toString(), upgradeSource);
  }).catch(error => {
    if (buyNowUsageTrackingProperties) {
      trackPurchaseOrderGenerationFailed(buyNowUsageTrackingProperties);
    }
    const chirpError = getChirpError(error);
    const chirpInternalError = getChirpInternalError(error);
    const sentryDetails = Object.assign({}, buyNowUsageTrackingProperties, {
      apiNames: requestedProducts.map(product => product.productApiName),
      buttonName: 'UIBuyNowButton'
    }, chirpError ? {
      chirpError
    } : {}, chirpInternalError ? {
      chirpInternalError
    } : {}, {
      request
    });
    logPurchaseOrderError('generatePurchaseOrder', error, sentryDetails);
    incrementPurchaseOrderGenerationFailure({
      type: 'buy-now'
    });
    throw error;
  });
};