/* eslint-disable @typescript-eslint/no-unused-vars */
import { fromJS } from 'immutable';
import { EQ, IN } from 'reporting-data/constants/operators';
import { getCustomFilters, getDataType, getDateRangeFilter, getDimensions, getDisplayParams, getFilterByPropertyName, getFrequency, getMetrics, getReportConfig, isTimeSeriesReport } from 'reporting-data/report/configReportGetters';
import { setCustomFilters, setDimensions, setDisplayParamsIntraperiod, setFrequency, updateFilterValueInReportByFilterPropertyAndOperator, updateFilterValuesInReportByFilterPropertyAndOperator } from 'reporting-data/report/configReportSetters';
import { setDimensions as setMultiConfigDimensions } from 'reporting-data/report/multiConfigReportSetters';
import { setReportChartType } from 'reporting-data/report/report-transformations/utils';
import * as FrequencyTypes from 'reporting-data/constants/frequency';
import { ENGAGEMENT } from 'reporting-data/constants/dataTypes';
import { getJourneyObjectFilters } from 'reporting-data/report/journeyReportGetters';
import { setJourneyObjectFilters } from 'reporting-data/report/journeyReportSetters';
import { getReportChartType, getReportEditorsSchema } from 'reporting-data/report/reportGetters';
import { isConfigReport, isJourneyReport, isMultiConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { isEmptyObject, isObject } from '../../lib/objectUtils';
import { ANALYTICS_LOCAL_STORAGE_KEYS } from './types';
import { deserializeStringArrayForLocalStorage, deserializeUrlParamObject, serializeObjectAsStringForLocalStorage, serializeStringArrayForLocalStorage, getIsValidDateRangeLocalStorageValue, getIsValidVisualizationLocalStorageValue } from './utils';
import { getVisualization } from 'reporting-data/report/multiConfigReportGetters';
import { DATE, OUTCOME } from 'reporting-data/constants/groupingTypes';
import { BUCKET_DEAL_PROGRESS, CLOSE_DATE } from 'reporting-data/constants/srvMigrationDimensionTypes';
export const AnalyticsSRVLocalStorageConfig = {
  [ANALYTICS_LOCAL_STORAGE_KEYS.CONFIG]: {
    getIsValidLocalStorageValue: localStorage => {
      var _localStorageValue$fi;
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.CONFIG];
      return isObject(localStorageValue) && getIsValidDateRangeLocalStorageValue((_localStorageValue$fi = localStorageValue.filters) === null || _localStorageValue$fi === void 0 || (_localStorageValue$fi = _localStorageValue$fi.dateRange) === null || _localStorageValue$fi === void 0 ? void 0 : _localStorageValue$fi.value);
    },
    getIsReportValidForConfig: report => isConfigReport(report) && !getReportConfig(report).isEmpty(),
    getValueFromReportForLocalStorage: report => isConfigReport(report) && getReportConfig(report).toJS(),
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const configValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.CONFIG];
      if (isConfigReport(report) && isObject(configValue) && !isEmptyObject(configValue)) {
        return report.set('config', fromJS(configValue));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.DISPLAY_PARAMS]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.DISPLAY_PARAMS];
      return isObject(localStorageValue);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report) && !getDisplayParams(report).isEmpty()) {
        return getDisplayParams(report).toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const displayParamsValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DISPLAY_PARAMS];
      if (isConfigReport(report) && isObject(displayParamsValue) && !isEmptyObject(displayParamsValue)) {
        return report.set('displayParams', fromJS(displayParamsValue));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.CHART_TYPE]: {
    getIsValidLocalStorageValue: localStorage => {
      return typeof localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.CHART_TYPE] === 'string' && !!localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.CHART_TYPE].length;
    },
    getIsReportValidForConfig: report => isJourneyReport(report) || isConfigReport(report),
    getValueFromReportForLocalStorage: report => getReportChartType(report),
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if ((isConfigReport(report) || isJourneyReport(report)) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.CHART_TYPE]) {
        return setReportChartType(report, localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.CHART_TYPE]) || report;
      }
      return report;
    },
    deserializeValue: value => {
      return value;
    },
    serializeValue: value => {
      return value;
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.EDITORS]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.EDITORS];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (!getReportEditorsSchema(report).isEmpty()) {
        var _getReportEditorsSche;
        return (_getReportEditorsSche = getReportEditorsSchema(report)) === null || _getReportEditorsSche === void 0 ? void 0 : _getReportEditorsSche.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const editorsValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.EDITORS];
      if (isConfigReport(report) && Array.isArray(editorsValue) && !!(editorsValue !== null && editorsValue !== void 0 && editorsValue.length)) {
        return report.set('editors', fromJS(editorsValue));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.DATE_RANGE]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.DATE_RANGE];
      return isObject(localStorageValue) && getIsValidDateRangeLocalStorageValue(localStorageValue);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      var _getDateRangeFilter;
      return isConfigReport(report) && ((_getDateRangeFilter = getDateRangeFilter(getReportConfig(report))) === null || _getDateRangeFilter === void 0 ? void 0 : _getDateRangeFilter.toJS().value);
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const dateRangeValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DATE_RANGE];
      if (isConfigReport(report) && isObject(dateRangeValue) && !isEmptyObject(dateRangeValue)) {
        if (isMultiConfigReport(report)) {
          report = report.update('reportConfigs', configs => configs.map(config => config.setIn(['filters', 'dateRange', 'value'], fromJS(dateRangeValue))));
        }
        report = report.setIn(['config', 'filters', 'dateRange', 'value'], fromJS(dateRangeValue));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.PIPELINE]: {
    getIsValidLocalStorageValue: localStorage => {
      var _localStorage$ANALYTI;
      return typeof localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.PIPELINE] === 'string' && !!((_localStorage$ANALYTI = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.PIPELINE]) !== null && _localStorage$ANALYTI !== void 0 && _localStorage$ANALYTI.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getFilterByPropertyN;
        return (_getFilterByPropertyN = getFilterByPropertyName(getReportConfig(report), 'pipeline')) === null || _getFilterByPropertyN === void 0 ? void 0 : _getFilterByPropertyN.get('value');
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      var _localStorageValue$AN;
      if (isConfigReport(report) && !!((_localStorageValue$AN = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.PIPELINE]) !== null && _localStorageValue$AN !== void 0 && _localStorageValue$AN.length)) {
        const originalConfig = getReportConfig(report);
        const updatedConfig = updateFilterValueInReportByFilterPropertyAndOperator(originalConfig, {
          property: 'pipeline',
          operator: EQ,
          value: localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.PIPELINE]
        });
        return report.set('config', updatedConfig);
      }
      return report;
    },
    deserializeValue: value => {
      return value;
    },
    serializeValue: value => {
      return value;
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.DEAL_PIPELINES]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.DEAL_PIPELINES];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getFilterByPropertyN2;
        return (_getFilterByPropertyN2 = getFilterByPropertyName(getReportConfig(report), 'pipeline')) === null || _getFilterByPropertyN2 === void 0 || (_getFilterByPropertyN2 = _getFilterByPropertyN2.get('values')) === null || _getFilterByPropertyN2 === void 0 ? void 0 : _getFilterByPropertyN2.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DEAL_PIPELINES]) {
        const originalConfig = getReportConfig(report);
        const updatedConfig = updateFilterValuesInReportByFilterPropertyAndOperator(originalConfig, {
          property: 'pipeline',
          operator: IN,
          values: fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DEAL_PIPELINES])
        });
        return report.set('config', updatedConfig);
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeStringArrayForLocalStorage(value);
    },
    serializeValue: value => {
      return serializeStringArrayForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.ACTIVITY_TYPES]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.ACTIVITY_TYPES];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getFilterByPropertyN3;
        return (_getFilterByPropertyN3 = getFilterByPropertyName(getReportConfig(report), 'hs_engagement_type')) === null || _getFilterByPropertyN3 === void 0 || (_getFilterByPropertyN3 = _getFilterByPropertyN3.get('values')) === null || _getFilterByPropertyN3 === void 0 ? void 0 : _getFilterByPropertyN3.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && getDataType(report) === ENGAGEMENT && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.ACTIVITY_TYPES]) {
        const originalConfig = getReportConfig(report);
        const updatedConfig = updateFilterValuesInReportByFilterPropertyAndOperator(originalConfig, {
          property: 'hs_engagement_type',
          operator: IN,
          values: fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.ACTIVITY_TYPES])
        });
        return report.set('config', updatedConfig);
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeStringArrayForLocalStorage(value);
    },
    serializeValue: value => {
      return serializeStringArrayForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.TEAMS]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.TEAMS];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getFilterByPropertyN4;
        return (_getFilterByPropertyN4 = getFilterByPropertyName(getReportConfig(report), 'hubspot_team_id')) === null || _getFilterByPropertyN4 === void 0 || (_getFilterByPropertyN4 = _getFilterByPropertyN4.get('values')) === null || _getFilterByPropertyN4 === void 0 ? void 0 : _getFilterByPropertyN4.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.TEAMS]) {
        const originalConfig = getReportConfig(report);
        const updatedConfig = updateFilterValuesInReportByFilterPropertyAndOperator(originalConfig, {
          property: 'hubspot_team_id',
          operator: IN,
          values: fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.TEAMS])
        });
        return report.set('config', updatedConfig);
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeStringArrayForLocalStorage(value);
    },
    serializeValue: value => {
      return value;
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.USERS]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.USERS];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getFilterByPropertyN5;
        return (_getFilterByPropertyN5 = getFilterByPropertyName(getReportConfig(report), 'hubspot_owner_id')) === null || _getFilterByPropertyN5 === void 0 || (_getFilterByPropertyN5 = _getFilterByPropertyN5.get('values')) === null || _getFilterByPropertyN5 === void 0 ? void 0 : _getFilterByPropertyN5.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.USERS]) {
        const originalConfig = getReportConfig(report);
        const updatedConfig = updateFilterValuesInReportByFilterPropertyAndOperator(originalConfig, {
          property: 'hubspot_owner_id',
          operator: IN,
          values: fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.USERS])
        });
        return report.set('config', updatedConfig);
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeStringArrayForLocalStorage(value);
    },
    serializeValue: value => {
      return serializeStringArrayForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.MEETING_OUTCOMES]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.MEETING_OUTCOMES];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getFilterByPropertyN6;
        return (_getFilterByPropertyN6 = getFilterByPropertyName(getReportConfig(report), 'hs_meeting_outcome')) === null || _getFilterByPropertyN6 === void 0 || (_getFilterByPropertyN6 = _getFilterByPropertyN6.get('values')) === null || _getFilterByPropertyN6 === void 0 ? void 0 : _getFilterByPropertyN6.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.MEETING_OUTCOMES]) {
        const originalConfig = getReportConfig(report);
        const updatedConfig = updateFilterValuesInReportByFilterPropertyAndOperator(originalConfig, {
          property: 'hs_meeting_outcome',
          operator: IN,
          values: fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.MEETING_OUTCOMES])
        });
        return report.set('config', updatedConfig);
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeStringArrayForLocalStorage(value);
    },
    serializeValue: value => {
      return serializeStringArrayForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.CUSTOM_FILTERS]: {
    getIsValidLocalStorageValue: (localStorage, report) => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.CUSTOM_FILTERS];
      if (isConfigReport(report)) {
        return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
      } else if (isJourneyReport(report)) {
        return !!localStorageValue && typeof localStorageValue === 'object' && !Array.isArray(localStorageValue);
      } else {
        return false;
      }
    },
    getIsReportValidForConfig: report => isConfigReport(report) || isJourneyReport(report),
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report) && !getCustomFilters(getReportConfig(report)).isEmpty()) {
        return getCustomFilters(getReportConfig(report)).toJS();
      }
      if (isJourneyReport(report) && !!getJourneyObjectFilters(report)) {
        return getJourneyObjectFilters(report).toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const filtersValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.CUSTOM_FILTERS];
      if (isConfigReport(report) && Array.isArray(filtersValue) && !!(filtersValue !== null && filtersValue !== void 0 && filtersValue.length)) {
        return report.set('config', setCustomFilters(getReportConfig(report), fromJS(filtersValue)));
      }
      if (isJourneyReport(report) && typeof filtersValue === 'object') {
        return setJourneyObjectFilters(report, fromJS(filtersValue));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.FREQUENCY]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.FREQUENCY];
      return Object.values(FrequencyTypes).includes(localStorageValue) && localStorageValue;
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report) && isTimeSeriesReport(report)) {
        return getFrequency(getReportConfig(report));
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const filtersValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.FREQUENCY];
      if (isConfigReport(report) && isTimeSeriesReport(report)) {
        return report.setIn(['config', 'frequency'], filtersValue);
      }
      return report;
    },
    deserializeValue: value => value,
    serializeValue: value => value
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.DIMENSIONS]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.DIMENSIONS];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length) && (localStorageValue === null || localStorageValue === void 0 ? void 0 : localStorageValue.length) <= 2;
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        var _getDimensions;
        return (_getDimensions = getDimensions(report)) === null || _getDimensions === void 0 ? void 0 : _getDimensions.toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DIMENSIONS]) {
        const updatedConfig = setDimensions(getReportConfig(report), fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DIMENSIONS]));
        report = report.set('config', updatedConfig);
        if (isMultiConfigReport(report)) {
          return setMultiConfigDimensions(report, getDimensions(report), fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.DIMENSIONS]));
        }
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeStringArrayForLocalStorage(value);
    },
    serializeValue: value => {
      return serializeStringArrayForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.METRICS]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.METRICS];
      return Array.isArray(localStorageValue) && !!(localStorageValue !== null && localStorageValue !== void 0 && localStorageValue.length);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report) && !getMetrics(report).isEmpty()) {
        return getMetrics(report).toJS();
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const metricsValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.METRICS];
      if (isConfigReport(report) && Array.isArray(metricsValue) && !!(metricsValue !== null && metricsValue !== void 0 && metricsValue.length)) {
        return report.setIn(['config', 'metrics'], fromJS(metricsValue));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.VISUALIZATION]: {
    getIsValidLocalStorageValue: (localStorage, report) => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.VISUALIZATION];
      const isValidVisualization = getIsValidVisualizationLocalStorageValue(localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.VISUALIZATION], report);
      return isValidVisualization && isObject(localStorageValue) && !!Object.keys(localStorageValue).length;
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      const reportVisualization = getVisualization(report);
      return reportVisualization && reportVisualization.toJS();
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isConfigReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.VISUALIZATION]) {
        return report.set('visualization', fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.VISUALIZATION]));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.GROUPING]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.GROUPING];
      return [DATE, OUTCOME].includes(localStorageValue);
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      var _getDimensions2;
      if (isConfigReport(report) && ((_getDimensions2 = getDimensions(report)) === null || _getDimensions2 === void 0 ? void 0 : _getDimensions2.size) !== 0) {
        var _getDimensions3;
        const dimensions = (_getDimensions3 = getDimensions(report)) === null || _getDimensions3 === void 0 ? void 0 : _getDimensions3.toJS();
        if (isConfigReport(report) && dimensions.includes(CLOSE_DATE) && getFrequency(getReportConfig(report)) === FrequencyTypes.DAY) {
          return DATE;
        }
        if (dimensions.includes(BUCKET_DEAL_PROGRESS)) {
          return OUTCOME;
        }
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const groupingValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.GROUPING];
      if (isConfigReport(report) && groupingValue) {
        var _getDimensions4;
        const dimensions = (_getDimensions4 = getDimensions(report)) === null || _getDimensions4 === void 0 ? void 0 : _getDimensions4.toJS();
        // When DATE is the grouping value
        // weighted forecast report has to have closedate as the first dimension
        if (groupingValue === DATE) {
          dimensions.unshift(CLOSE_DATE);
          // Setting frequency to DAY and prepending closedate to dimensions
          const updatedConfig = setFrequency(setDimensions(getReportConfig(report), fromJS(dimensions)), FrequencyTypes.DAY);
          return report.set('config', updatedConfig);
        }
        // When OUTCOME is the grouping value
        // deals won report has to have BUCKET_dealProgress as the only dimension
        if (groupingValue === OUTCOME) {
          const updatedConfig = setDimensions(getReportConfig(report), fromJS([BUCKET_DEAL_PROGRESS]));
          return report.set('config', updatedConfig);
        }
      }
      return report;
    },
    deserializeValue: value => {
      return value;
    },
    serializeValue: value => {
      return JSON.stringify(value);
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.INTRA_PERIOD]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.INTRA_PERIOD];
      return typeof localStorageValue === 'boolean';
    },
    getIsReportValidForConfig: isConfigReport,
    getValueFromReportForLocalStorage: report => {
      if (isConfigReport(report)) {
        return getReportConfig(report).get('intraPeriod');
      }
    },
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      const intraPeriodValue = localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.INTRA_PERIOD];
      if (isConfigReport(report) && typeof intraPeriodValue === 'boolean' && localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.GROUPING] === DATE) {
        return setDisplayParamsIntraperiod(report.setIn(['config', 'intraPeriod'], intraPeriodValue.toString()), intraPeriodValue.toString());
      }
      return report;
    },
    deserializeValue: value => {
      return Boolean(value);
    },
    serializeValue: value => {
      return value.toString();
    }
  },
  [ANALYTICS_LOCAL_STORAGE_KEYS.JOURNEY_DATE]: {
    getIsValidLocalStorageValue: localStorage => {
      const localStorageValue = localStorage[ANALYTICS_LOCAL_STORAGE_KEYS.JOURNEY_DATE];
      if (!isObject(localStorageValue)) {
        return false;
      }
      const objectKeys = Object.keys(localStorageValue);
      return objectKeys.includes('propertyFilterOperation') || objectKeys.includes('dateFrom') && objectKeys.includes('dateTo');
    },
    getIsReportValidForConfig: isJourneyReport,
    getValueFromReportForLocalStorage: report => report.getIn(['journeyQuery', 'eventDateInterval']),
    updateReportFromValueInLocalStorage: (report, localStorageValue) => {
      if (isJourneyReport(report) && !!localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.JOURNEY_DATE]) {
        return report.setIn(['journeyQuery', 'eventDateInterval'], fromJS(localStorageValue[ANALYTICS_LOCAL_STORAGE_KEYS.JOURNEY_DATE]));
      }
      return report;
    },
    deserializeValue: value => {
      return deserializeUrlParamObject(value);
    },
    serializeValue: value => {
      return serializeObjectAsStringForLocalStorage(value);
    }
  }
};