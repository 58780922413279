import { isMultiConfigReport } from 'reporting-data/tsTypes/reportTypes';
import { AnalyticsSRVLocalStorageConfig } from './localStorageEditorConfig';
import { isObject } from '../../lib/objectUtils';
import { Set as ImmutableSet } from 'immutable';
import { getConfigs } from 'reporting-data/report/multiConfigReportGetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
const COMMA_ENCODED_URL_SEARCH_PARAM = ',';
const isValidSRVLocalStorageKey = key => {
  return key in AnalyticsSRVLocalStorageConfig;
};
export const convertReportToLocalStorageStateForAnalyticsInSRV = report => {
  return Object.keys(AnalyticsSRVLocalStorageConfig).reduce((map, localStorageKey) => {
    const localStorageGetter = AnalyticsSRVLocalStorageConfig[localStorageKey].getValueFromReportForLocalStorage;
    const localStorageChecker = AnalyticsSRVLocalStorageConfig[localStorageKey].getIsReportValidForConfig;
    if (localStorageChecker(report)) {
      map[localStorageKey] = localStorageGetter(report);
    }
    return map;
  }, {});
};
export const setEditorValuesOnReportFromLocalStorage = (report, localStorage) => {
  return Object.keys(AnalyticsSRVLocalStorageConfig).reduce((reportAccumulator, localStorageKey) => {
    let updatedReport = reportAccumulator;
    const localStorageUpdater = AnalyticsSRVLocalStorageConfig[localStorageKey].updateReportFromValueInLocalStorage;
    const localStorageChecker = AnalyticsSRVLocalStorageConfig[localStorageKey].getIsReportValidForConfig;
    const localStorageValueValidator = AnalyticsSRVLocalStorageConfig[localStorageKey].getIsValidLocalStorageValue;
    if (localStorageKey in localStorage && localStorageChecker(report) && localStorageValueValidator(localStorage, report)) {
      updatedReport = localStorageUpdater(reportAccumulator, localStorage);
    }
    return updatedReport;
  }, report);
};
export const deserializeUrlParamObject = param => {
  try {
    const deserializedVal = JSON.parse(decodeURIComponent(atob(param)));
    return isObject(deserializedVal) ? deserializedVal : {};
  } catch (e) {
    return {};
  }
};
export const serializeObjectAsStringForLocalStorage = value => {
  return btoa(encodeURIComponent(JSON.stringify(value)));
};
export const serializeStringArrayForLocalStorage = value => {
  return value.join(COMMA_ENCODED_URL_SEARCH_PARAM);
};
export const deserializeStringArrayForLocalStorage = value => {
  return value.split(COMMA_ENCODED_URL_SEARCH_PARAM);
};
export const validateSRVStorage = value => {
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    return Object.keys(value).reduce((storage, key) => {
      if (isValidSRVLocalStorageKey(key)) {
        storage[key] = value[key];
      }
      return storage;
    }, {});
  }
  return {};
};
export const getIsValidDateRangeLocalStorageValue = value => {
  return isObject(value) && !!(value !== null && value !== void 0 && value.rangeType);
};
export const getIsValidVisualizationLocalStorageValue = (value, report) => {
  if (report && isMultiConfigReport(report) && getReportChartType(report) === 'TABLE' && value.columns) {
    const reportConfigDatasetKeys = getConfigs(report).keySeq().toSet();
    const localStorageVisualizationDatasetKeys = ImmutableSet(value.columns.map(column => column.dataset).filter(dataset => dataset !== 'primary'));
    return localStorageVisualizationDatasetKeys.every(key => reportConfigDatasetKeys.has(key));
  }
  return true;
};