module.exports = {
  "/": {
    "name": "index-redirect"
  },
  "/pages": {
    "name": "pages-index-redirect"
  },
  "/pages/site": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "cms pql loaded": [
        "CONTENT_CMS_PQL_LOADED"
      ],
      "cms hub site page zero state": [
        "CMS_HUB_SITE_PAGE_ZERO_STATE"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/pages/landing": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/pages/landing/folders": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/pages/landing/analyze": {
    "success": {
      "analyze tab loaded": [
        "LOAD_ANALYZE_TAB"
      ]
    }
  },
  "/pages/site/analyze": {
    "success": {
      "analyze tab loaded": [
        "LOAD_ANALYZE_TAB"
      ]
    }
  },
  "/pages/site/:viewId": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "cms pql loaded": [
        "CONTENT_CMS_PQL_LOADED"
      ],
      "cms hub site page zero state": [
        "CMS_HUB_SITE_PAGE_ZERO_STATE"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/pages/landing/:viewId": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/blog": {
    "name": "blog-index-redirect"
  },
  "/blog/posts": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "cms hub onboarding loaded": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/blog/posts/:viewId": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ],
      "cms hub onboarding loaded": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "no access locked state": [
        "NO_ACCESS_LOCKED_STATE"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/blog/comments": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/blog/tags": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/blog/authors": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/blog/listing": {
    "success": {
      "onboarding state": [
        "CONTENT_CMS_HUB_ONBOARDING_LOADED"
      ],
      "start state": [
        "CONTENT_START_STATE_LOADED"
      ],
      "empty state": [
        "CONTENT_EMPTY_STATE_LOADED"
      ],
      "content loaded": [
        "CONTENT_TABLE_LOADED"
      ]
    },
    "error": [
      "CONTENT_LOAD_FAILED",
      "APP_LOAD_FAILED"
    ]
  },
  "/tree": {
    "name": "site-tree",
    "success": {
      "canvas": [
        "SITE_TREE_CANVAS_LOADED"
      ]
    }
  },
  "/splash": {
    "name": "cms-splash-redirect"
  }
};