import PortalIdParser from 'PortalIdParser';
import { createClient } from 'laboratory-lib';
import experiments from '../../../experiments.yaml';
export const laboratoryClient = createClient({
  name: 'content-management-ui-laboratory-client',
  quickFetchLabel: 'experiment-treatments',
  experiments: experiments,
  identifiers: {
    portalId: PortalIdParser.get()
  }
});