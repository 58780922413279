import { AnalyticsSRVLocalStorageConfig } from './localStorageEditorConfig';
export const serializeLocalStorageForUrl = localStorageValue => {
  const serializedValue = Object.keys(localStorageValue).reduce((acc, currLocalStorageKey) => {
    if (localStorageValue[currLocalStorageKey]) {
      acc[currLocalStorageKey] = AnalyticsSRVLocalStorageConfig[currLocalStorageKey].serializeValue(localStorageValue[currLocalStorageKey]);
    }
    return acc;
  }, {});
  return new URLSearchParams(serializedValue).toString();
};
export const deserializeLocalStorageFromUrl = param => {
  const jsonLocalStorage = Object.fromEntries(new URLSearchParams(param));
  return Object.keys(jsonLocalStorage).filter(key => key in AnalyticsSRVLocalStorageConfig).reduce((acc, currLocalStorageKey) => {
    const localStorageValue = AnalyticsSRVLocalStorageConfig[currLocalStorageKey].deserializeValue(jsonLocalStorage[currLocalStorageKey]);
    if (localStorageValue) {
      acc[currLocalStorageKey] = AnalyticsSRVLocalStorageConfig[currLocalStorageKey].deserializeValue(jsonLocalStorage[currLocalStorageKey]);
    }
    return acc;
  }, {});
};