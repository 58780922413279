import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["externalOptionsReferenceType", "externalOptions"];
import { List } from 'immutable';
import I18n from 'I18n';
import PropertyOptionRecord from 'customer-data-objects/property/PropertyOptionRecord';
import { AudienceAccessTypeToAccessControl } from 'ui-memberships-settings-lib/constants/AccessControlTypes';
import { AudienceAccessTypes } from 'ui-memberships-settings-lib/constants/AudienceAccessTypes';
import { getContentAccessTypeTagI18nKeyFromAccessControlType, getContentAccessTypeTableFilterOptions } from 'ui-memberships-settings-lib/utils/contentAccessTypeTag';
import { ContentTypes } from '../../shared/constants/ContentTypeConstants';
import { isFilterAvailableForScopes, isFilterAvailableForGates
// @ts-expect-error Untyped module
} from '../../shared/utils/filterAvailabilityProperties';
import Auth from '../../Auth';
// @ts-expect-error Untyped module
import { isAppViewSiteTree } from '../../shared/utils/urls';
import { getTableViewContentTypeId } from '../../shared/utils/utils';
import { isEqualOperator, isRangeOperator, isInOrNotInOperator } from './typeCheckUtils';
import { ApprovalStatus } from 'ui-product-approvals/types';
import { ContentNameKeysToCrmPropertyNames } from '../../shared/constants/contentProperties';
const VisibleProperties = [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.CAMPAIGN, ContentNameKeysToCrmPropertyNames.CREATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_DATE, ContentNameKeysToCrmPropertyNames.DOMAIN, ContentNameKeysToCrmPropertyNames.LANGUAGE, ContentNameKeysToCrmPropertyNames.PUBLISH_DATE, ContentNameKeysToCrmPropertyNames.TEAM, ContentNameKeysToCrmPropertyNames.UPDATED_BY, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.NAME];
const ColumnVisibleProperties = [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS, ContentNameKeysToCrmPropertyNames.CAMPAIGN, ContentNameKeysToCrmPropertyNames.CREATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_DATE, ContentNameKeysToCrmPropertyNames.DOMAIN, ContentNameKeysToCrmPropertyNames.LANGUAGE, ContentNameKeysToCrmPropertyNames.PUBLISH_DATE, ContentNameKeysToCrmPropertyNames.TEAM, ContentNameKeysToCrmPropertyNames.UPDATED_BY, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.TITLE, ContentNameKeysToCrmPropertyNames.NAME];
const AdditionalPagesVisibleProperties = [ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.THEME_PATH, ContentNameKeysToCrmPropertyNames.TEST_STATUS];
const AdditionalSiteTreeVisibleProperties = [ContentNameKeysToCrmPropertyNames.CONTENT_TYPE, ContentNameKeysToCrmPropertyNames.RESOLVED_LANGUAGE, ContentNameKeysToCrmPropertyNames.THEME_PATH, ContentNameKeysToCrmPropertyNames.TEST_STATUS];
const AdditionalPostsVisibleProperties = [ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR, ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID, ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.TAG, ContentNameKeysToCrmPropertyNames.THEME_PATH];
const AdditionalBLPVisibleProperties = [ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID];
export const PropertiesWithCustomLabel = [ContentNameKeysToCrmPropertyNames.AUDIENCE_ACCESS, ContentNameKeysToCrmPropertyNames.CREATED_BY, ContentNameKeysToCrmPropertyNames.CREATED_DATE, ContentNameKeysToCrmPropertyNames.UPDATED_DATE, ContentNameKeysToCrmPropertyNames.TEST_STATUS, ContentNameKeysToCrmPropertyNames.VARIATION, ContentNameKeysToCrmPropertyNames.STATE, ContentNameKeysToCrmPropertyNames.CONTENT_GROUP_ID, ContentNameKeysToCrmPropertyNames.THEME_PATH, ContentNameKeysToCrmPropertyNames.NAME, ContentNameKeysToCrmPropertyNames.BLOG_AUTHOR];
const PropertiesWithColumnDisplayLabel = [ContentNameKeysToCrmPropertyNames.NAME];
export const getPropertyFilterValueFromFilterOperators = (filters, propertyName) => {
  const foundFilter = filters.find(f => f.property === propertyName);
  if (!foundFilter) return undefined;
  if (isInOrNotInOperator(foundFilter)) {
    return foundFilter.values;
  }
  if (isEqualOperator(foundFilter) || isRangeOperator(foundFilter)) {
    return foundFilter.value;
  }
  return undefined;
};
export const getPropertyLabel = propertyName => I18n.text(`content-management-ui.shared.properties.labels.${propertyName}`);
export const getPropertyColumnDisplayLabel = propertyName => I18n.text(`content-management-ui.shared.properties.columnDisplayLabels.${propertyName}`);
const isPropertyHidden = (property, isColumnProperty) => {
  const name = property.name;
  const {
    user,
    gates
  } = Auth.get();
  const contentType = getTableViewContentTypeId();
  const isVisibleInPages = (contentType === ContentTypes.LANDING_PAGE || contentType === ContentTypes.SITE_PAGE) && AdditionalPagesVisibleProperties.includes(name);
  const isVisibleInBlogPosts = contentType === ContentTypes.BLOG_POST && AdditionalPostsVisibleProperties.includes(name);
  const isVisibleInBlogListingPages = contentType === ContentTypes.BLOG_LISTING_PAGE && AdditionalBLPVisibleProperties.includes(name);
  const isVisibleInSiteTree = isAppViewSiteTree() && AdditionalSiteTreeVisibleProperties.includes(name);
  const isPropertyAvailableForScopes = isFilterAvailableForScopes(name, user, contentType);
  const isPropertyUngated = isFilterAvailableForGates(property.name, gates);
  const isHiddenForColumns = !(isColumnProperty && isPropertyAvailableForScopes && (isPropertyUngated || property.name === ContentNameKeysToCrmPropertyNames.NAME) && (ColumnVisibleProperties.includes(name) || isVisibleInBlogPosts || isVisibleInPages || isVisibleInBlogListingPages));
  const isHiddenOtherwise = !isPropertyAvailableForScopes || !isPropertyUngated || !(VisibleProperties.includes(name) || isVisibleInPages || isVisibleInBlogPosts || isVisibleInSiteTree || isVisibleInBlogListingPages);
  return isColumnProperty ? isHiddenForColumns : isHiddenOtherwise;
};
export const transformPropertyOverride = (isColumnProperty = false) => property => {
  const name = property.name;
  const hidden = isPropertyHidden(property, isColumnProperty);
  let label;
  if (!hidden) {
    label = isColumnProperty && PropertiesWithColumnDisplayLabel.includes(name) ? getPropertyColumnDisplayLabel(name) : getPropertyLabel(name);
  }
  const restProperty = _objectWithoutPropertiesLoose(property, _excluded);
  return Object.assign({}, restProperty, {
    externalOptions: false,
    hidden,
    label: label || property.label
  });
};
const transformPropertyOverrideForPropertyMap = transformPropertyOverride(true);
export const transformPropertyOverrideForFilterEditor = transformPropertyOverride(false);
export const transformPropertyMap = propertyDefinitionMap => {
  const transformedMap = {};
  Object.keys(propertyDefinitionMap).forEach(key => {
    transformedMap[key] = transformPropertyOverrideForPropertyMap(propertyDefinitionMap[key]);
  });
  return transformedMap;
};
export const isViewWithPages = contentType => contentType === ContentTypes.LANDING_PAGE || contentType === ContentTypes.SITE_PAGE || isAppViewSiteTree();
export const getCustomPropertyOverrides = ({
  auth,
  propertyDefinitionMap,
  contentType,
  areApprovalsEnabled
}) => {
  const {
    user,
    gates
  } = auth.get();

  // We need to run `transform` on the propertyMap's properties again here because:
  // When we manually apply transform to all hardcoded properties where the propertyOverride is defined,
  // those property definitions are for column display, but there may be slight differences in presentation
  // for those properties in the filter editor - eg. hs_name should have label: "Name and URL" in the table,
  // but just "Name" in the filter editor.
  const transformedPropertiesForFilterEditor = {};
  Object.keys(propertyDefinitionMap).forEach(key => {
    transformedPropertiesForFilterEditor[key] = transformPropertyOverrideForFilterEditor(propertyDefinitionMap[key]);
  });
  let propertyMap = Object.assign({}, transformedPropertiesForFilterEditor, {
    [ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS]: {
      hidden: !areApprovalsEnabled,
      label: getPropertyLabel(ContentNameKeysToCrmPropertyNames.APPROVAL_STATUS),
      type: 'enumeration',
      displayOrder: -1
    },
    [ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT]: {
      hidden: !(isFilterAvailableForGates(ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT, gates) && isFilterAvailableForScopes(ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT, user)),
      label: getPropertyLabel(ContentNameKeysToCrmPropertyNames.BUSINESS_UNIT),
      type: 'enumeration',
      displayOrder: -1
    }
  });
  if (isViewWithPages(contentType)) {
    propertyMap = Object.assign({}, propertyMap, {
      [ContentNameKeysToCrmPropertyNames.VARIATION]: {
        hidden: !isFilterAvailableForScopes(ContentNameKeysToCrmPropertyNames.VARIATION, user, contentType),
        label: getPropertyLabel(ContentNameKeysToCrmPropertyNames.VARIATION),
        type: 'enumeration',
        displayOrder: -1
      }
    });
  }
  return propertyMap;
};
export const isValueAsList = value => List.isList(value);
export const getContentAccessTypeFilterText = selectedValue => {
  const accessControlType = AudienceAccessTypeToAccessControl[selectedValue];
  return I18n.text(getContentAccessTypeTagI18nKeyFromAccessControlType(accessControlType));
};
export const getContentAccessTypeFilterOptions = userHasBlogWallAccess => {
  const audienceAccessTypes = [AudienceAccessTypes.PUBLIC, AudienceAccessTypes.PRIVATE_PASSWORD_REQUIRED, AudienceAccessTypes.PRIVATE_REGISTRATION_REQUIRED, AudienceAccessTypes.PRIVATE_SSO_REQUIRED];
  if (userHasBlogWallAccess) {
    audienceAccessTypes.push(AudienceAccessTypes.PRIVATE_SELF_REGISTRATION_REQUIRED);
  }
  return getContentAccessTypeTableFilterOptions(audienceAccessTypes);
};
export const FilterablePublishStates = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  ARCHIVED: 'archived'
};
export const getPublishStatusLangText = val => I18n.text(`content-management-ui.header.filters.publishStatus.${val}`);
export const getContentPublishStatusFilterOptions = () => [{
  text: getPublishStatusLangText(FilterablePublishStates.DRAFT),
  value: FilterablePublishStates.DRAFT
}, {
  text: getPublishStatusLangText(FilterablePublishStates.PUBLISHED),
  value: FilterablePublishStates.PUBLISHED
}, {
  text: getPublishStatusLangText(FilterablePublishStates.SCHEDULED),
  value: FilterablePublishStates.SCHEDULED
}];
export const getContentTypeFilterLangText = val => I18n.text(`content-management-ui.header.filters.contentType.${val}`);
export const getContentTypeFilterOptions = () => {
  return [{
    text: getContentTypeFilterLangText(`${ContentTypes.LANDING_PAGE}`),
    value: ContentTypes.LANDING_PAGE
  }, {
    text: getContentTypeFilterLangText(`${ContentTypes.SITE_PAGE}`),
    value: ContentTypes.SITE_PAGE
  }];
};
export const getLanguageFilterOptions = languageOptions => languageOptions ? languageOptions.reduce((acc, option) => {
  acc.push({
    text: option.label,
    value: option.value
  });
  return acc;
}, []) : [];
export const getThemeName = themeOptions => val => {
  const theme = themeOptions.find(t => t.value === val);
  return theme ? theme.text : val;
};
export const ApprovalStatusToFilterValue = {
  default: 'No Approval Requested',
  [ApprovalStatus.APPROVAL_PENDING]: 'pending',
  [ApprovalStatus.APPROVED]: 'approved',
  [ApprovalStatus.CANCELLED]: 'cancelled'
};
export const ApprovalValueToStatus = {
  'No Approval Requested': 'default',
  approved: ApprovalStatus.APPROVED,
  cancelled: ApprovalStatus.CANCELLED,
  pending: ApprovalStatus.APPROVAL_PENDING
};
export const getApprovalStatusLangText = val => I18n.text(`productApprovals.ApprovalStatusTag.${val}`);
export const getApprovalStatusFilterOptions = () => {
  return [{
    text: getApprovalStatusLangText('default'),
    value: 'default'
  }, {
    text: getApprovalStatusLangText(ApprovalStatus.APPROVAL_PENDING),
    value: ApprovalStatus.APPROVAL_PENDING
  }, {
    text: getApprovalStatusLangText(ApprovalStatus.APPROVED),
    value: ApprovalStatus.APPROVED
  }];
};
export const getApprovalStatusFilterOptionsForLegacyFilters = () => {
  return List([PropertyOptionRecord({
    label: getApprovalStatusLangText('default'),
    value: 'default'
  }), PropertyOptionRecord({
    label: getApprovalStatusLangText(ApprovalStatus.APPROVAL_PENDING),
    value: ApprovalStatus.APPROVAL_PENDING
  }), PropertyOptionRecord({
    label: getApprovalStatusLangText(ApprovalStatus.APPROVED),
    value: ApprovalStatus.APPROVED
  })]);
};