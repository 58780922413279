export const ANALYTICS_LOCAL_STORAGE_KEYS = {
  CONFIG: 'config',
  DISPLAY_PARAMS: 'displayParams',
  CHART_TYPE: 'chartType',
  EDITORS: 'editors',
  PIPELINE: 'pipeline',
  DATE_RANGE: 'dateRange',
  ACTIVITY_TYPES: 'activityTypes',
  TEAMS: 'teams',
  USERS: 'ownerIds',
  MEETING_OUTCOMES: 'meetingOutcomeTypes',
  CUSTOM_FILTERS: 'customFilters',
  DEAL_PIPELINES: 'dealPipelines',
  FREQUENCY: 'frequency',
  METRICS: 'metrics',
  DIMENISIONS: 'dimensions',
  INTRA_PERIOD: 'intraperiod',
  DIMENSIONS: 'dimensions',
  VISUALIZATION: 'visualization',
  GROUPING: 'grouping',
  JOURNEY_DATE: 'journeyDate'
};