/**
 * CRUD Crm Object types
 */

export const CREATE_OBJECTS = 'CREATE_OBJECTS';
export const DELETE_OBJECTS = 'DELETE_OBJECTS';
export const UPDATE_OBJECTS = 'UPDATE_OBJECTS';
export const CLEAR_DELETED_OBJECTS = 'CLEAR_DELETED_OBJECTS';
export const UPDATE_PROPERTY_VALUES = 'UPDATE_PROPERTY_VALUES';

/**
 * Team Partitioning
 */

export const MANAGE_ACCESS_SUCCESS = 'MANAGE_ACCESS_SUCCESS';

/**
 * Misc events
 */
export const CHANGE_UI_MODE = 'CHANGE_UI_MODE';
export const CLOSE_EDIT_COLUMNS_MODAL = 'CLOSE_EDIT_COLUMNS_MODAL';
export const CLOSE_EXPORT_MODAL = 'CLOSE_EXPORT_MODAL';
export const CLOSE_CUSTOMIZE_GALLERY_SIDE_PANEL = 'CLOSE_CUSTOMIZE_GALLERY_SIDE_PANEL';
export const DESELECT_OBJECTS = 'DESELECT_OBJECTS';
export const EMPTY_STATE_CHANGE = 'EMPTY_STATE_CHANGE';
export const OPEN_EDIT_COLUMNS_MODAL = 'OPEN_EDIT_COLUMNS_MODAL';
export const OPEN_EXPORT_MODAL = 'OPEN_EXPORT_MODAL';
export const OPEN_CUSTOMIZE_GALLERY_SIDE_PANEL = 'OPEN_CUSTOMIZE_GALLERY_SIDE_PANEL';
export const REFRESH_RESOLVED_CELL = 'REFRESH_RESOLVED_CELL';
export const SET_BULK_SELECTION_MODE = 'SET_BULK_SELECTION_MODE';
export const UPDATE_BULK_ACTIONS_STATE = 'UPDATE_BULK_ACTIONS_STATE';
export const UNREGISTER_PARENT_ROW = 'UNREGISTER_PARENT_ROW';
export const RESET_MULTI_LANGUAGE_GROUP_STATE = 'RESET_MULTI_LANGUAGE_GROUP_STATE';